import { getGlobalLogger } from '@zg-rentals/logger-base';
import type { LogHttpRequest } from '@zg-rentals/monitor-base';
import { getGlobalMonitor } from '@zg-rentals/monitor-base';
import { makeModuleInstance } from '@zg-rentals/util';

type ErrorContext = Record<string, unknown>;
const errorContext = makeModuleInstance<ErrorContext>({});
export const MAX_STACK_TRACE_LINES = 15;
export const MAX_STACK_TRACE_LENGTH = 1_500;

/**
 * Adds global error context that will be logged with all other error information to Splunk and Datadog.
 * Local context passed to `logError` will take precedence and be spread _after_ the global context
 */
export const setGlobalErrorContext = (ctx: ErrorContext) => errorContext.set({ ...errorContext.get(), ...ctx });

export const getGlobalErrorContext = errorContext.get;

const enabled = makeModuleInstance(true);

/**
 * An escape hatch to disable log error, perhaps if you intend to redirect logged
 * out users and have a bunch of pending promises/network requests that will be
 * terminated by redirecting.
 */
export const disableLogError = () => enabled.set(false);

export type { LogHttpRequest };

/**
 * Throw this error to attach context to the error that can be consumed higher up
 * in the stack. In general this error type should be used only in module code to
 * propagate error context out to apps that call `logError`.
 */
export class ContextError extends Error {
  constructor(
    name: string,
    public readonly context: Record<string, unknown>,
  ) {
    super(name);
    this.name = name;
  }

  public static is(error: unknown): error is ContextError {
    return error instanceof ContextError;
  }
}

export function truncateStackTrace(stack?: string) {
  if (!stack) return '[no stack trace provided]';

  let splitLines = stack.split('\n');
  if (splitLines.length > MAX_STACK_TRACE_LINES) {
    splitLines = splitLines.slice(0, MAX_STACK_TRACE_LINES).concat('...');
  }

  const newStack = splitLines.join('\n');
  return newStack.length > MAX_STACK_TRACE_LENGTH ? newStack.slice(0, MAX_STACK_TRACE_LENGTH) + '...' : newStack;
}

/**
 * Call this function to log errors in the Rental Platform standardized way. Assuming
 * your app is configured with a Bootstrap instance, your errors should show up in some
 * combination of Splunk, Marlin, and Datadog (based on bootstrap configuration).
 */
export function logError({
  error,
  context,
  errorType = error instanceof Error ? error.name : 'UnknownError',
  httpRequest,
}: {
  error: Error | unknown;
  context?: string | Record<string, unknown>;
  errorType?: string;
  httpRequest?: LogHttpRequest;
}) {
  if (!enabled.get()) {
    return;
  }

  try {
    const isServer = typeof window === 'undefined';
    const isBrowser = !isServer;
    const isErrorInstance = error instanceof Error;
    const errorPrefix = `${isServer ? 'httpServer' : 'browser'} error`;

    let fullContext = typeof context === 'string' ? { context } : context ?? {};

    if (isBrowser) {
      fullContext.originPath = window.location.origin + window.location.pathname;
      fullContext.userAgent = window.navigator.userAgent;
    }

    // Merge context error context, taking care to let passed-in context value(s) take precedence
    if (ContextError.is(error)) {
      fullContext = { ...error.context, ...fullContext };
    }

    // Get stack trace and truncate (otherwise the Splunk log will lose its formatting)
    const stack = isErrorInstance ? error.stack : new Error().stack;
    fullContext = {
      stack: truncateStackTrace(stack),
      ...errorContext.get(),
      ...fullContext,
    };

    // Serialize error into a readable string, useful for Splunk logs
    const message = isErrorInstance ? error.message : `Serialized error: ${JSON.stringify(error)}`;

    // some error names have white space, like Apollo "Invariant Violation"
    errorType = errorType.replace(/\s/g, '');
    const prefixedErrorType = `${errorPrefix} type ${errorType}`;

    // splunk
    const logger = getGlobalLogger(prefixedErrorType);
    if (!logger) {
      throw new Error('RpBootstrap: logger not initialized. You should add a bootstrap to your app');
    }
    logger.error(fullContext, message);

    // marlin
    const monitor = getGlobalMonitor();
    monitor?.count({ name: `${errorPrefix} total` });
    monitor?.count({ name: prefixedErrorType });

    // datadog
    if (isBrowser) {
      // @ts-ignore (will throw TS error in other apps' builds)
      window.DD_RUM?.addError?.(error, { ...fullContext, errorType: prefixedErrorType });
    }

    if ((error as Error).stack) {
      monitor?.error({ error: error as Error, httpRequest, context: fullContext });
    }
  } catch (e) {
    // if somehow this logging function fails, we don't want to crash the app
    console.error(e); // eslint-disable-line no-console
  }
}
