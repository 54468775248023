import assign from 'lodash/assign';
import merge from 'lodash/merge';

import constants from 'app/shared/constants/ConstantsBundle';
import CrimeScoreCollection from 'app/shared/models/CrimeScoreCollection';
import InstantTourForm from 'app/shared/models/InstantTourForm';
import AvailableToursForListing from 'app/shared/models/AvailableToursForListing';
import reduxUtils from 'app/shared/utils/reduxUtils';
import { updateUserItemTypes } from 'app/shared/utils/listingsReducerUtils';
import type { ListingDetails } from 'app/types/listingDetails.type';

interface CrimeStats {
  violentCrimes: string;
  allCrimes: string;
  areaName: string;
  crimeScore: string;
}

interface CrimeScores {
  localCrime: CrimeStats;
  cityCrime: CrimeStats;
  nearbyCrime: Array<unknown>;
  localCrimeRank: string;
  radius: string;
}

export interface CurrentListingDetailsState {
  activeMarkerMaloneLotId: string | null;
  crimeScores: CrimeScores;
  userCanReplyToReviews: boolean;
  indexInList: number | null;
  existsOutsideOfList: boolean;
  lastVisitedListingFromListUri: string | null;
  currentListing: ListingDetails | null;
  instantTourForm: InstanceType<typeof InstantTourForm> | null;
  availableToursForListing: InstanceType<typeof AvailableToursForListing>;
  isLoadingTourAvailability: boolean;
  tourAvailabilityError: string | null;
}

const initState = (): CurrentListingDetailsState => ({
  activeMarkerMaloneLotId: null,
  crimeScores: new CrimeScoreCollection(),
  userCanReplyToReviews: false,

  // used to keep track of HDPs position in list view
  indexInList: null,

  // this current listing is outside the scope of the current list. e.g. hdp -> related listing (not in current byCoords list)
  existsOutsideOfList: false,

  // store the last listing in the list so we can go back to it.
  // this is necessary, since the list will change once we do a byCoords call (visit an HDP outside of our current list)
  lastVisitedListingFromListUri: null,

  currentListing: null,

  // Keep track of a user's progress in instant touring for a listing.
  instantTourForm: new InstantTourForm(),

  // Store the available tour dates for a listing.
  availableToursForListing: new AvailableToursForListing(),

  isLoadingTourAvailability: false,
  tourAvailabilityError: null,
});

const mapActionsToReducer = {
  [constants.LOAD_LISTING_CRIME_SCORES]: (
    state: CurrentListingDetailsState,
    action: { payload: { crimeScores: CrimeScores } },
  ) => {
    const { crimeScores } = action.payload;

    return assign({}, state, { crimeScores });
  },
  [constants.SET_CURRENT_LISTING_INDEX]: (
    state: CurrentListingDetailsState,
    action: {
      payload: {
        index: number;
      };
    },
  ) => {
    const index = action.payload;

    return assign({}, state, { indexInList: index });
  },
  [constants.SET_USER_REVIEW_REPLY_PERMISSION_BOOL]: (
    state: CurrentListingDetailsState,
    action: { payload: boolean },
  ) => {
    return assign({}, state, {
      userCanReplyToReviews: action.payload,
    });
  },
  [constants.SET_CURRENT_LISTING_OUTSIDE_OF_LIST]: (
    state: CurrentListingDetailsState,
    action: { payload: boolean },
  ) => {
    return assign({}, state, {
      existsOutsideOfList: action.payload,
    });
  },
  [constants.SET_LAST_VISITED_LISTING_FROM_LIST]: (state: CurrentListingDetailsState, action: { payload: string }) => {
    return assign({}, state, {
      lastVisitedListingFromListUri: action.payload,
    });
  },
  [constants.CLEAR_CURRENT_LISTING]: (state: CurrentListingDetailsState) => {
    return assign({}, state, {
      currentListing: null,
    });
  },
  [constants.SET_CURRENT_LISTING]: (state: CurrentListingDetailsState, action: { currentListing: ListingDetails }) => {
    const currentListing = action.currentListing;
    return assign({}, state, {
      activeMarkerMaloneLotId:
        currentListing && currentListing.maloneLotIdEncoded
          ? currentListing.maloneLotIdEncoded
          : state.activeMarkerMaloneLotId,
      currentListing,
    });
  },
  [constants.SET_ACTIVE_MARKER_MALONE_LOT_ID]: (
    state: CurrentListingDetailsState,
    action: { activeMarkerMaloneLotId: string },
  ) => {
    return assign({}, state, {
      activeMarkerMaloneLotId: action.activeMarkerMaloneLotId,
    });
  },
  [constants.FETCH_LISTING_UPDATE]: (
    state: CurrentListingDetailsState,
    action: { payload: { listingUpdates: ListingDetails } },
  ) => {
    const listingUpdates = action.payload.listingUpdates;
    let currentListing = {} as ListingDetails;

    if (listingUpdates.aliasEncoded === state.currentListing?.aliasEncoded) {
      currentListing = assign({}, state.currentListing);
      if (listingUpdates.contact) {
        currentListing.contact = listingUpdates.contact;
      }
      if (listingUpdates.popularity) {
        currentListing.popularity = listingUpdates.popularity;
      }
      if (listingUpdates.listedBy) {
        currentListing.listedBy = listingUpdates.listedBy;
      }
    }

    return assign({}, state, {
      currentListing,
    });
  },
  // TODO: add unit tests for hp tour reducers.
  [constants.UPDATE_INSTANT_TOUR_FORM]: (state: CurrentListingDetailsState, action: { payload: InstantTourForm }) => {
    const currentInstantTourForm = assign({}, state.instantTourForm);
    const updatedInstantTourFormFields = action.payload;
    const updatedInstantTourForm = { ...currentInstantTourForm, ...updatedInstantTourFormFields };

    return assign({}, state, {
      instantTourForm: updatedInstantTourForm,
    });
  },
  [constants.CLEAR_INSTANT_TOUR_FORM]: (state: CurrentListingDetailsState) => {
    return assign({}, state, {
      instantTourForm: null,
    });
  },
  [constants.UPDATE_AVAILABLE_TOURS_FOR_LISTING]: (
    state: CurrentListingDetailsState,
    action: {
      payload: {
        availableDatesWithOrWithoutTimes: Array<string>;
        dateOnly: boolean;
        scheduledDateWithOrWithoutTime: string;
        zoneId: string;
      };
    },
  ) => {
    const {
      availableDatesWithOrWithoutTimes = [],
      dateOnly = false,
      scheduledDateWithOrWithoutTime = '',
      zoneId = '',
    } = action.payload;

    return assign({}, state, {
      availableToursForListing: new AvailableToursForListing({
        availableDatesWithOrWithoutTimes,
        dateOnly,
        scheduledDateWithOrWithoutTime,
        zoneId,
      }),
    });
  },
  [constants.USER_ITEM_OPTIMISTIC_TOGGLE]: (
    state: CurrentListingDetailsState,
    action: { payload: { listing: ListingDetails; type: string; action: string } },
  ) => {
    const listing = action.payload.listing;
    let { currentListing } = state;

    if (!listing || !listing.maloneLotIdEncoded || !listing.geo) {
      return state;
    }

    const type = action.payload.type; // favorite, viewed, hidden, inquiry, etc.
    const addOrRemove = action.payload.action; // add, remove
    // Update locally in currentListing
    if (currentListing && listing.maloneLotIdEncoded === currentListing.maloneLotIdEncoded) {
      currentListing = merge({}, currentListing); // must clone before mutating!
      currentListing.userItemTypes = updateUserItemTypes(currentListing.userItemTypes, addOrRemove, type);

      // TODO: Investigate if this is needed -- can't find any listing examples with "units" field on HPWEB
      // if (listing.belongsToMultipleUnitBuilding) {
      //   let matchingUnitInCurrentListing = find(currentListing.units, (unit) => {
      //     return unit.aliasEncoded === listing.aliasEncoded;
      //   });

      //   if (matchingUnitInCurrentListing) {
      //     matchingUnitInCurrentListing = merge({}, matchingUnitInCurrentListing);
      //     matchingUnitInCurrentListing.userItemTypes = updateUserItemTypes(
      //       matchingUnitInCurrentListing.userItemTypes,
      //       addOrRemove,
      //       type,
      //     );

      //     const indexOfMatchingUnit = findIndex(currentListing.units, (unit) => {
      //       return unit.aliasEncoded === listing.aliasEncoded;
      //     });
      //     currentListing.units.splice(indexOfMatchingUnit, 1, matchingUnitInCurrentListing);
      //   }
      // }

      return assign({}, state, {
        currentListing,
      });
    } else {
      return state;
    }
  },
};

const currentListingDetails = reduxUtils.createReducer(mapActionsToReducer, initState());

export default currentListingDetails;
