import * as Env from '@zg-rentals/rental-platform-config';
import { isbot } from 'isbot';

const namespaces = ['production', 'development', 'kubernetes_staging'] as const;

export type Namespace = (typeof namespaces)[number];

export function getNamespace(): Namespace {
  const envType: Namespace | undefined =
    typeof process !== 'undefined' ? (process.env?.RP_ENV_TYPE as Namespace) : undefined;
  if (envType && namespaces.includes(envType)) {
    return envType;
  }
  return 'development';
}

let isPrinted = false;

function printDevMessage() {
  if (!isPrinted) {
    // eslint-disable-next-line no-console
    console.log('We are in dev mode, metrics data will not be sent');
    isPrinted = true;
  }
}

function printProdMessage() {
  if (!isPrinted) {
    // eslint-disable-next-line no-console
    console.log(
      'We are in production mode, metrics data will be sent. If you are running a dev server something is' +
        ' not correct.',
    );
    isPrinted = true;
  }
}

export function isDev() {
  // bots
  if (isBrowserBot()) {
    return true;
  }

  // docker validate
  if (typeof process !== 'undefined' && process.env?.CI_VALIDATE) {
    printDevMessage();
    return true;
  }

  // next js dev mode
  if (typeof process !== 'undefined' && process.env?.NODE_ENV === 'development') {
    printDevMessage();
    return true;
  }

  // webpack dev mode __DEV__
  // @ts-ignore
  if (typeof __DEV__ !== 'undefined' && __DEV__) {
    printDevMessage();
    return true;
  }

  const isClientSide = typeof window !== 'undefined';
  const isClientSideEnabled =
    isClientSide &&
    (Env.getCurrentEnvForClientSide() === Env.PRODUCTION ||
      Env.getCurrentEnvForClientSide() === Env.COMET_1 ||
      Env.getCurrentEnvForClientSide() === Env.STAGING);

  if (isClientSide && !isClientSideEnabled) {
    printDevMessage();
    return true;
  }

  if ((typeof process !== 'undefined' && process.env?.isRjs !== 'true') || isClientSideEnabled) {
    printProdMessage();
  }

  return false;
}

export function isJest() {
  return (
    typeof process !== 'undefined' && (process.env?.NODE_ENV === 'test' || process.env.JEST_WORKER_ID !== undefined)
  );
}

function isCypress() {
  // cypress check
  // @ts-ignore
  if (typeof window !== 'undefined' && !!window.Cypress) {
    return true;
  }
  return false;
}

export function isTest() {
  if (isCypress()) {
    return true;
  }

  // jest check
  return isJest();
}

function isBrowserBot() {
  if (typeof window !== 'undefined' && isbot(window.navigator?.userAgent)) {
    return true;
  }
  if (isCypress()) {
    return true;
  }
  return false;
}

export function injectAppNameHeader(headers?: Record<string, string>, appName?: string) {
  const newHeaders = { ...headers };
  if (appName) {
    newHeaders['x-datarouter-account-name'] = appName;
  }
  return newHeaders;
}

export function getEnvironmentUrl(stagingUrl: string, productionUrl: string) {
  const namespace = getNamespace();
  if (namespace === 'kubernetes_staging') {
    return stagingUrl;
  }
  return productionUrl;
}

// required to consolidate with java
const envMappings: Record<string, string> = {
  'comet-1': 'comet1',
  'ttest-2': 'ttest2',
  'ztest-1': 'ztest1',
  'ztest-2': 'ztest2',
  'ztest-3': 'ztest3',
  'ztest-4': 'ztest4',
  'ztest-5': 'ztest5',
  'hptest-3': 'hptest3',
  'ptest-1': 'ptest1',
  'www.qa.zillow.net': 'comet1',
  'comet1.testpads.net': 'comet1',
  'www.ra1.zillow.net': 'ztest1',
  'www.ra2.zillow.net': 'ztest2',
  'www.ra3.zillow.net': 'ztest3',
  'www.ra4.zillow.net': 'ztest4',
  'www.ra5.zillow.net': 'ztest5',
};

export function getEnvironment() {
  const isClientSide = typeof window !== 'undefined';
  let env: string | undefined = undefined;
  if (isClientSide) {
    if (Env.getCurrentEnvForClientSide() === Env.COMET_1 || Env.getCurrentEnvForClientSide() === Env.STAGING) {
      env = window.location.hostname;
    }
  } else {
    const namespace = getNamespace();
    if (namespace === 'kubernetes_staging') {
      env = process.env?.ZG_ENV ?? 'kubernetes_staging';
    }
  }

  // consolidate environments with java
  if (env && envMappings[env]) {
    env = envMappings[env];
  }

  return env;
}
